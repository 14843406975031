import router from '@/router';
import { computed, defineComponent, inject } from 'vue';
export default defineComponent({
    props: ['withExchangeButtom'],
    setup: function () {
        var authStore = inject('authState');
        var goTo = function (url) {
            router.push({ name: url });
        };
        return {
            userData: computed(function () { return authStore.state.userData; }),
            goTo: goTo
        };
    },
});
