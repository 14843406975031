import { computed, defineComponent, inject, onBeforeMount } from 'vue';
import PointsHistoryTable from '@/components/PointsHistoryTable.vue';
import PointsInfo from '@/components/PointsInfo.vue';
import PointsLoadingMobile from '@/components/PointsLoadingMobile.vue';
import NoPoints from '@/components/NoPoints.vue';
export default defineComponent({
    components: { PointsHistoryTable: PointsHistoryTable, PointsInfo: PointsInfo, PointsLoadingMobile: PointsLoadingMobile, NoPoints: NoPoints },
    setup: function () {
        var recordStore = inject('recordStore');
        var authStore = inject('authState');
        onBeforeMount(function () {
            authStore.getUserDetails();
        });
        return {
            loading: computed(function () { return recordStore.state.recordState.loading; }),
            recordStore: recordStore,
        };
    },
});
